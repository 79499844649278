import { IDocumentoResponse, IDocumentosResponse, IDocumentosResponseAntigo, IFuncionario, IFuncionarioBody, IFuncionarioDetalheResponse, ITiposDocumentoResponse, ITiposDocumentoResponseAntigo } from "../@interfaces";
import { IDocumentosFuncionarioBody, IDocumentosFuncionarioBodyAntigo } from "../@interfaces/docFuncionario";
import { base64 } from "../mocks";
import storage from "../storage";
import Api from "./APIAxios";





const _docMock = {
  documento: 0,
  base64
}



const APIFuncionario = {
  getFuncionario: async function (body: IFuncionarioBody): Promise<IFuncionarioDetalheResponse> {
    try {
      const url = storage.getClientUrl();
      const response = await Api.post(`${url}/Funcionario`, body);

      if (response?.data?.status === 0 || response?.data?.status === 126) return { funcionario: response.data };

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {
      return { error: true, message: "Algo deu errado" };
    }
  },


  getDocumentos: async function (body: IDocumentosFuncionarioBody): Promise<IDocumentosResponse> {
    try {
      const url = storage.getClientUrl();
      const response = await Api.post<IDocumentosResponse>(`${url}/ListaDocumentosFuncionario`, body);


      if (response?.data?.status === 0 || response?.data?.status === 126) return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {
      return { error: true, message: error };
    }
  },


  getDocumentosAntigo: async function (body: IDocumentosFuncionarioBodyAntigo): Promise<IDocumentosResponseAntigo> {
    try {
      const url = storage.getClientUrl();
      const response = await Api.post<IDocumentosResponseAntigo>(`${url}/ListaDocumentos`, body);


      if (response?.data?.status === 0 || response?.data?.status === 126) return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {
      return { error: true, message: error };
    }
  },


  getDocumento: async function (body: any): Promise<IDocumentoResponse> {
    try {
      const url = storage.getClientUrl();
      // const response = await Api.post<IFuncionarioResponse>(`${url}/funcionario`, body);

      await new Promise(resolve => setTimeout(resolve, 2000));
      const response = {
        data: {
          status: 0,
          mensagem: "Sucesso",
          documento: _docMock
        }
      }

      if (response?.data?.status === 0 || response?.data?.status === 126) return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {
      return { error: true, message: error };
    }
  },



  getTiposDocumentoVersaoAntiga: async function (): Promise<ITiposDocumentoResponseAntigo> {
    try {
      const url = storage.getClientUrl();
      const response = await Api.get<ITiposDocumentoResponseAntigo>(`${url}/ListaTiposDocumentos`);

      if (response?.data?.status === 0 || response?.data?.status === 126) return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {
      return { error: true, message: error };
    }
  },


  getTiposDocumentoFuncionario: async function (): Promise<ITiposDocumentoResponse> {
    try {
      const url = storage.getClientUrl();
      const response = await Api.get<ITiposDocumentoResponse>(`${url}/ListaTiposDocumentosFuncionarios`);

      if (response?.data?.status === 0 || response?.data?.status === 126) return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {
      return { error: true, message: error };
    }
  }
}


export default APIFuncionario;