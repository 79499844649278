import { IDocumentosResponse, IDocumentosResponseAntigo, ITiposDocumentoResponse } from "../@interfaces";
import storage from "../storage";
import ApiHK from "./ApiHK";
import Api from "./APIAxios";
import { IDocumentosEmpresaClienteBody, IDocumentosEmpresaClienteBodyAntigo } from "../@interfaces/docEmpresaCliente";

const mock = {
  data:{
    documentos: [
      {
        tipoDocumento: 2,
        arquivo: 9989,
        descricaoArquivo: "exemplo",
        funcionario: 3,
        fimPeriodo: '-',
        inicioPeriodo: '-',
        nomeFuncionario: 'Maria',
        assinado: true
      },
      {
        tipoDocumento: 2,
        arquivo: 9999,
        descricaoArquivo: "exemplo 2",
        funcionario: 3,
        fimPeriodo: '-',
        inicioPeriodo: '-',
        nomeFuncionario: 'Maria',
        assinado: true
      }
    ],
    
    status: 0,
    mensagem: "Sucesso",
    error: false,
  },
}

const APIDocumentos = {
  getDocumentos: async (body: IDocumentosEmpresaClienteBody): Promise<IDocumentosResponse> => {
    try {
      const url = storage.getClientUrl();
      const response = await Api.post<IDocumentosResponse>(`${url}/ListaDocumentosEmpresaCliente`, body);
      // const response = mock

      if (response?.data?.status === 0 || response?.data?.status === 126) return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {
      return { error: true, message: error };
    }
  },

  getDocumentosAntigo: async (body: IDocumentosEmpresaClienteBodyAntigo): Promise<IDocumentosResponseAntigo> => {
    try {
      const url = storage.getClientUrl();
      const response = await Api.post<IDocumentosResponseAntigo>(`${url}/ListaDocumentos`, body);
      // const response = mock

      if (response?.data?.status === 0 || response?.data?.status === 126) return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {
      return { error: true, message: error };
    }
  },


  getTiposDocumento: async () : Promise<ITiposDocumentoResponse> => {
    try{
      const url = storage.getClientUrl();
      const response = await Api.get<ITiposDocumentoResponse>(`${url}/ListaTiposDocumentosEmpresaCliente`);

      if (response?.data?.status === 0 || response?.data?.status === 126) return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };

    } catch(error) {
      return { error: true, message: error };

    }
  }
}


export default APIDocumentos;