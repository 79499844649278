import React, { createContext, useEffect, useState } from "react"
import storage from "../storage";
//@ts-ignore
import jwt_decode from "jwt-decode";
import Utils from "../utils/auxiliar"
interface IContextProps {
  isLogged: boolean;
  loggedEmail: string | null;
  signIn: (email: string,  versaoServidor: string, acessos: Number[] | boolean) => void;
  signOut: () => void;
  isMasterUser: () => boolean;
  setIsPassExpired: (param: boolean) => void;
  isPassExpired?: boolean;
  acessos: Number[];
  setAcessos: (acessos: Number[]) => void;
  validaAcessos: boolean;
  setValidaAcessos: (param: boolean) => void,
  versaoServidor: string,
  setVersaoServidor: (param: string) => void
}


const AuthContext = createContext<IContextProps>({} as IContextProps);

export const AuthContextProvider: React.FC = ({ children }) => {
  const [isLogged, setIsLogged] = useState<boolean>(!!storage.getLoggedUser());
  const [loggedEmail, setLoggedEmail] = useState<string | null>(storage.getLoggedUser());
  const [isPassExpired, setIsPassExpired] = useState<boolean>(false);
  const [acessos, setAcessos] = useState<Number[]>([]);
  const [validaAcessos, setValidaAcessos] = useState<boolean>(false);
  const [versaoServidor, setVersaoServidor] = useState<string>('');

  useEffect(() => {
    const logged = storage.getLoggedUser();
    const acessos = storage.getAcess() as Number[];
    if (logged) {
      setIsLogged(true);
    }

    if (acessos) {
      setAcessos(acessos);
      setValidaAcessos(true);
    }
  }, [])


  function signIn(email: string, versaoServidor: string, acessos?: Number[] | boolean, ) {
    if (acessos && (Array.isArray(acessos))) {
      Utils.setAccessUser(setAcessos, acessos);
      setValidaAcessos(true);
    }
    setIsPassExpired(false);
    setVersaoServidor(versaoServidor);
    storage.setLoggedUser(email);
    setLoggedEmail(email);
    setIsLogged(true);
  }

  function signOut() {
    storage.clearAll();
    setIsLogged(false);
    setValidaAcessos(false);
    setAcessos([])
    if (isPassExpired) {
      setIsPassExpired(true);
    }
  }


  function isMasterUser() {
    const token = storage.getAccessToken();
    const { hkum } = jwt_decode(token);
    return hkum;
  }


  return (
    <AuthContext.Provider
      value={{
        isLogged,
        loggedEmail,
        signIn,
        signOut,
        isPassExpired,
        setIsPassExpired,
        isMasterUser,
        acessos,
        setAcessos,
        validaAcessos,
        setValidaAcessos,
        versaoServidor,
        setVersaoServidor
        
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;